<template>
  <div v-loading="loading">
    <div class="pd-x-3">
      <div>
        <h1 class="text-center">Profile</h1>
      </div>
      <el-row :gutter="20" v-if="useroidc">
        <el-col :span="12" :offset="0">
          <h4>preferred_username</h4>
          {{ useroidc.profile.preferred_username }}
        </el-col>
        <el-col :span="12" :offset="0">
          <h4>name</h4>
          {{ useroidc.profile.name }}
        </el-col>
        <el-col :span="12" :offset="0">
          <h4>email</h4>
          {{ useroidc.profile.email }}
        </el-col>
        <el-col :span="12" :offset="0">
          <h4>email_verified</h4>
          {{ useroidc.profile.email_verified }}
        </el-col>
        <el-col :span="12" :offset="0">
          <h4>email_verified</h4>
          {{ Date(useroidc.expires_at) | dateTimeTh }}
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12" :offset="0">
          <h4>access_token</h4>
          {{ useroidc.access_token }}
        </el-col>
        <el-col :span="12" :offset="0">
          <h4>id_token</h4>
          {{ useroidc.id_token }}
        </el-col>
        <el-col :span="24" :offset="0" class="mg-t-1 text-center">
          <el-button type="primary" size="default" @click="getUserProfile()"
            >Profile</el-button
          >
        </el-col>
        <el-col :span="24" :offset="0" class="mg-t-1 text-center">
          <el-button type="primary" size="default" @click="logout()"
            >Logout</el-button
          >
        </el-col>
      </el-row>
      <pre>{{ useroidc }}</pre>
    </div>
  </div>
</template>

<script>
import AuthService from "@/service/oidcService";
import { HTTP } from "@/service/axios";
import axios from "axios";
export default {
  data() {
    return {
      loading: false
    };
  },
  computed: {
    useroidc() {
      return this.$store.state.useroidc;
    }
  },
  async mounted() {
    AuthService.events.addAccessTokenExpiring(async () => {
      let res = await AuthService.signinSilentCallback().catch(error => {
        AuthService.getUser().then(user => {
          console.log("getUser", user);
        });
      });
    });

    AuthService.events.addAccessTokenExpired(async () => {
      console.log("Session expired. Going out! ", arguments);
      let res = await AuthService.signinSilentCallback().catch(error => {
        AuthService.getUser().then(user => {
          console.log("getUser", user);
        });
      });
    });
  },
  methods: {
    async getUserProfile() {
      // var result = await AuthService.getUser();
      // this.$store.commit("SET_USER_OIDC", result);
      let res = await AuthService.getUser().then(user => {
        return user;
      });
    },

    logout() {
      AuthService.signoutRedirect();
      this.fullLoading = true;
      setTimeout(() => {
        window.localStorage.eram.clear();
      }, 200);

      window.localStorage.removeItem("eram");
      delete HTTP.defaults.headers.common.Authorization;
    }
  }
};
</script>

<style>
</style>